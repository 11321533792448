# Menu déroulant sur mobile
jQuery(document).on 'app.ready', ->

  $nav    = $ '.nav'
  $toggle = $nav.find '.toggle'
  $menu   = $ $toggle.attr 'href'
  active  = 'active'

  $toggle.click (e) ->
    e.preventDefault()
    e.stopPropagation()
    $nav.toggleClass active

  $ document
    .click (e) ->
      $nav.removeClass active
