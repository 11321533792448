# Gestion de la video sur la page "Société"
jQuery(document).on 'app.ready', ->

  $video = $ '#video'
  return if !$video.size()

  $container = $video.find '.video-container'
  src = $container.data 'src'

  $iframe = $ '<iframe/>'
    .attr
      allowfullscreen: true
      frameborder: 0
      src: src

  $container.html $iframe
