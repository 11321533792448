# Validation et envoi du formulaire de contact
# Utilise jQuery FormValidation + jQuery Form
# @link http://formvalidation.io/
# @link http://malsup.com/jquery/form/
jQuery(document).on 'app.ready', ->

  do ($form = $ '#contact-form') ->

    $output = $form
      .find '.form-output'
      .empty()
      .hide()

    # Affichage du message de succès ou d'erreur
    showMessage = (params = className: 'alert-danger', message: 'Une erreur est survenue') ->
      $output
        .hide()
        .empty()
        .removeClass 'alert-success alert-warning alert-danger'
        .addClass params.className
        .html params.message
        .slideDown 'slow'
        .delay 5000
        .slideUp 'fast'

    # Soumission du formulaire
    $form
      .formValidation()
      .on 'success.form.fv', (e) ->
        e.preventDefault()

        $form.ajaxSubmit

          beforeSubmit: ->
            $output
              .hide()
              .empty()
              .removeClass 'alert-success alert-warning alert-danger'

          success: (response) ->
            output = null

            if ('status' of response) && ('content' of response)
              output =
                className: if response.status then 'alert-success' else 'alert-warning'
                message: response.content
              if response.status
                $form.clearForm()
                $form.find '.form-control-feedback'
                  .hide()

            showMessage output

          error: ->
            showMessage()
