# Polyfill input placeholder
# Utilise jQuery Placeholder
# @link https://github.com/mathiasbynens/jquery-placeholder
jQuery(document).on 'app.ready', ->

  do ($form = $ '#contact-form') ->

    return if Modernizr.input.placeholder

    $form
      .find 'input, textarea'
      .placeholder()

