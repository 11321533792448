# Galerie photos
# Utilise Owl Carousel
# @link http://owlgraphic.com/owlcarousel/index.html#customizing
jQuery(document).on 'app.ready', ->

  $ '.owl-carousel'
    .owlCarousel
      autoHeight: true
      autoPlay:   8000
      lazyLoad:   true
      singleItem: true


