# Google Map page contact
jQuery(document).on 'app.ready', ->

  $map = $ '#contact-map'

  return if $map.size() is 0
  return if typeof google is 'undefined'

  latitude  = $map.data 'latitude'
  longitude = $map.data 'longitude'
  zoom      = $map.data 'zoom'
  latLng    = new google.maps.LatLng latitude, longitude
  center    = if $(window).width() > 1023 then new google.maps.LatLng latitude, longitude + 0.02 else latLng

  mapOptions =
    center:         center
    draggable:      !Modernizr.touch
    mapTypeControl: false
    scrollwheel:    false
    styles:         [ { "stylers": [ { "saturation": -100 } ] } ]
    zoom:           zoom

  id = $map.attr 'id'
  map = new google.maps.Map document.getElementById(id), mapOptions

  markerOptions =
    animation: google.maps.Animation.DROP
    map:       map
    position:  latLng

  if 'imgUrl' of window
    markerOptions.icon = window.imgUrl + '/contact/marker.png'

  setTimeout ->
    marker = new google.maps.Marker markerOptions
  , 1000
