# Homepage
jQuery(document).ready ($) ->

  # Le bloc qui accueille le contenu Ajax
  $container = $ '#ajax-container'
  return if !$container.size()

  $document = $ @
  $body = $ 'body'
  $home = $ '#home-content'

  $home
    .find '.nav'
    .find 'a'
    .not '.toggle'
    .click (e) ->
      e.preventDefault()
      $link = $ @
      url = $link.attr 'href'

      # Charge en Ajax la page correspondante
      $container.load url + ' #ajax-content', ->

        $banner = $container.find '.banner'
        $nav = $container.find '.nav'
        # Hauteur du header = chevauchement du container et de la home
        height = $nav.offset().top + $nav.outerHeight() - $home.outerHeight()
        $container.css 'margin-top', - height
        top = height - $home.height()
        $banner.addClass 'animated'

        # Animation
        $home.animate { 'margin-top': top }, 1000, 'easeOutQuint', ->

          # La home disparait
          $home.fadeOut 'fast', ->
            $home.remove()
            $container.css 'margin-top', 0
            # Animation du header
            $banner.removeClass 'animated'
            # La page devient scrollable
            $body.removeClass 'home-body'

            # Déclenche les scripts au chargement
            $document.trigger 'app.ready'

            # Met à jour l'URL du navigateur
            if Modernizr.history
              window.history.pushState {}, '', url
