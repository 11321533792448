jQuery(document).on 'app.ready', ->

  # "Smooth scroll" au clic sur une ancre
  $ 'a[href^=#]'
    .not('[href=#]')
    .not('.toggle')
    .click (e) ->
      e.preventDefault()
      target  = @hash
      $target = $(target)
      return if $target.size() is 0
      $ 'html, body'
        .stop()
        .animate { 'scrollTop': $target.offset().top }, 500, 'swing', ->
          window.location.hash = target

  # Désactive les liens téléphone sur PC
  if !Modernizr.touch
    $ 'a[href^=tel]'
      .click (e) ->
        e.preventDefault()
